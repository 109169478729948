import React from "react";

import Skills from "./skills";

import INFO from "../../data/user";

import "./styles/allskills.css";

const AllSkills = () => {
	return (<>
		<h1>Back-end</h1>
		<div className="all-projects-container">
			{INFO.backend.map((skills, index) => (
				<div className="all-projects-project" key={index}>
					<Skills
						logo={skills.logo}
						title={skills.title}
					/>
				</div>
			))}
		</div>

		<h1>Database</h1>
		<div className="all-projects-container">
			{INFO.database.map((skills, index) => (
				<div className="all-projects-project" key={index}>
					<Skills
						logo={skills.logo}
						title={skills.title}
					/>
				</div>
			))}
		</div>

		<h1>Front-end</h1>
		<div className="all-projects-container">
			{INFO.frontend.map((skills, index) => (
				<div className="all-projects-project" key={index}>
					<Skills
						logo={skills.logo}
						title={skills.title}
					/>
				</div>
			))}
		</div>

		<h1>Multimedia Production and Design</h1>
		<div className="all-projects-container">
			{INFO.MultimediaProductionandDesign.map((skills, index) => (
				<div className="all-projects-project" key={index}>
					<Skills
						logo={skills.logo}
						title={skills.title}
					/>
				</div>
			))}
		</div>
		</>
	);
};

export default AllSkills;
