import React from "react";

function article_1() {
	return {
		date: "11 November 2023",
		title: "Develop Desktop Apps with PHP 🖥️💪.",
		description:
			"This revolutionary advancement bridges the gap between PHP and the native system environment.",
		keywords: [
			"PHP",
			"PHP Native",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<img
						src="https://miro.medium.com/v2/resize:fit:720/0*zgRYLXE72VKqK5Do"
						alt="PHP Native logo"
						className="randImage"
					/>
					<p>
					🚀 Exciting News! Introducing NativePHP: Develop Desktop Apps with PHP 🖥️💪
I am thrilled to share some groundbreaking news with all my LinkedIn connections, courtesy of the brilliant mind of Marcel Pociot! Get ready for the release of NativePHP, a game-changing package that allows you to develop desktop applications using PHP. 🌟

NativePHP empowers developers to create feature-rich, secure, and high-performance desktop apps that seamlessly interact with native system APIs. This revolutionary advancement bridges the gap between PHP and the native system environment, opening up endless possibilities for the PHP community. 💻

What sets NativePHP apart is its remarkable versatility. It enables the development of Linux and Mac apps, supporting both AMD and ARM architectures, without the need for pre-installed PHP. With NativePHP, you can dive straight into desktop app development without any hassle! 🎉
Marcel Pociot’s NativePHP, backed by the power of Laravel, offers a comprehensive suite of features, including queues, schedulers, and database support. This impressive package makes it an ideal choice for creating robust desktop applications. 💼

An exciting aspect of NativePHP is its framework-agnostic nature. While initially providing a Laravel package, NativePHP can be leveraged with any framework or even without one. It truly unlocks the full potential of PHP for desktop app development, empowering developers to push boundaries and innovate. 🔑

I am delighted to announce that Marcel Pociot will be releasing NativePHP at @LaraconUS on July 20. Save the date and be part of the future of desktop app development with PHP! 🗓️

Let’s join Marcel Pociot in unleashing the true potential of PHP and creating exceptional desktop applications with NativePHP. If you’re as excited as I am, leave a comment below and let’s make waves in the PHP community together! 💥
					</p>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "09 November 2023",
		title: "The most insightful stories about React Native.",
		description:
			"Read stories about React Native on Medium. Discover smart, unique perspectives on React Native and the topics that matter most to you like React.",
		style: ``,
		keywords: [
			"React native",
		],
		body: (
			<div className="article-content">
					<img
						src="https://res.cloudinary.com/practicaldev/image/fetch/s--G5AWXQ2H--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_800/https://dev-to-uploads.s3.amazonaws.com/i/v4y43jjfj7u5r8to8qdu.png"
						alt="React native logo"
						className="randImage"
					/>
					<p>
					In this article, we will explore React Native, an exciting step into mobile app development.


React Native is an open source framework developed by Facebook and provides a JavaScript-based mobile application development solution. In addition to being JavaScript-based, software developers can also use TypeScript. This powerful tool allows developers to use the same code base to create native mobile applications for iOS and Android platforms. With its cross-platform compatibility, rapid development features, wide community support and modular structure, React Native becomes an ideal option for beginners. You can get a quick start and open the doors to mobile application development with basic steps such as installing Node.js and npm and installing React Native CLI.


An important step you need to know before starting React Native projects is to install Node.js and npm on your computer. Node.js provides a JavaScript-powered runtime environment, and npm (Node Package Manager) is used to manage JavaScript packages. You can easily install these tools by downloading the latest versions from their respective official websites. After installing Node.js and npm, you can install the React Native Command Line Interface (CLI) tools required to launch and manage your React Native projects. This step is important for you to step into the world of React Native and prepares you for the exciting journey of mobile app development. Now that we have completed these basic steps, we are ready to take our first step towards the fascinating world of React Native!
					</p>
			</div>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
