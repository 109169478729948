import React from "react";


import "./styles/skills.css";

const Skills = (props) => {
    const { logo, title } = props;

    return (
        <React.Fragment>
            <div className="skill">
                <div className="skill-container">
                    <div className="skill-logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="skill-title">{title}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Skills;
