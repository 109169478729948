const INFO = {
	main: {
		title: "AKIF Soufiane's Portfolio",
		name: "AKIF Soufiane",
		email: "contact@akifsoufiane.tech",
		logo: "../logo.webp",
	},

	socials: {
		github: "https://github.com/Soufianeakif",
		linkedin: "https://ma.linkedin.com/in/soufiane-akif",
		stackoverflow: "https://stackoverflow.com/users/16494830/soufiane-akif",
	},

	homepage: {
		title: "I'm AKIF Soufiane, A Full-stack web and mobile app developer.",
		description:
			"As a web developer, I am driven by my desire to learn more technologies, and I believe that my strong work ethic, attention to detail, and solving problems make me a valuable asset to any team. I am excited to continue growing and learning in my field, and I am confident that my passion and dedication will help me achieve my goals. Thank you for taking the time to learn more about me, and I look forward to the opportunity to work with you.",
	},

	about: {
		title: "I'm AKIF Soufiane. I live in Rabat Morocco, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [

		{
			title: "TicTacToe",
			description:
				"Mobile Application for playing Tic Tac Toe with friends, developed using React Native, featuring real-time multiplayer gameplay and a user-friendly interface.",
			logo: "https://icons.veryicon.com/png/o/business/vscode-program-item-icon/react-3.png",
			linkText: "View Project",
			link: "https://github.com/Soufianeakif/TicTacToe",
		},

		{
			title: "MedinaDelivery",
			description:
				"Web Application to streamline and optimize delivery operations. This application offers comprehensive tools for planning, tracking, and optimizing deliveries.",
			logo: "https://pbs.twimg.com/profile_images/1163911054788833282/AcA2LnWL_400x400.jpg",
			linkText: "View Project",
			link: "https://github.com/Soufianeakif",
		},

		{
			title: "Centre d'affaire",
			description:
				"Web Application to manage a business center, rent rooms and also buy packs, there are customer interfaces and others for admins to manage customers and products.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
			linkText: "View Project",
			link: "https://centredaffaire.rf.gd/",
		},

		{
			title: "CasaMena",
			description:
				"Desktop Application tailored specifically for hotel management. This powerful tool simplifies guest reservations.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
			linkText: "View Project",
			link: "https://github.com/Soufianeakif/",
		},


	],

	backend: [

		{
			title: "Laravel",
			description:
				"Mobile Application for playing Tic Tac Toe with friends, developed using React Native, featuring real-time multiplayer gameplay and a user-friendly interface.",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png",
		},
		{
			title: "Php",
			description:
				"Mobile Application for playing Tic Tac Toe with friends, developed using React Native, featuring real-time multiplayer gameplay and a user-friendly interface.",
			logo: "https://pngimg.com/d/php_PNG35.png",
		},
		{
			title: "C#",
			logo: "https://seeklogo.com/images/C/c-sharp-c-logo-02F17714BA-seeklogo.com.png",
		},
		{
			title: "Python",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
		},
		{
			title: "Rest API",
			description:
				"Mobile Application for playing Tic Tac Toe with friends, developed using React Native, featuring real-time multiplayer gameplay and a user-friendly interface.",
			logo: "https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/rest-api-icon.png",
		},

	],
	database: [
		{
			title: "MySQL",
			logo: "https://pngimg.com/d/mysql_PNG7.png",
		},
		{
			title: "SQLServer",
			logo: "https://cdn-icons-png.flaticon.com/512/5968/5968364.png",
		},
		{
			title: "PostgreSQL",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/993px-Postgresql_elephant.svg.png",
		},
		{
			title: "OracleDB",
			logo: "https://i0.wp.com/www.aceql.com/wp-content/uploads/2021/08/Oracle-Database.png?fit=500%2C250&ssl=1",
		},

	],
	frontend: [

		{
			title: "HTML5",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/800px-HTML5_Badge.svg.png",
		},
		{
			title: "CSS3",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/800px-CSS3_logo.svg.png",
		},
		{
			title: "Tailwind CSS",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/320px-Tailwind_CSS_Logo.svg.png",
		},
		{
			title: "Bootstrap",
			logo: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/bootstrap-5-logo-icon.png",
		},
		{
			title: "React.js",
			logo: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
		},
		{
			title: "React Native",
			logo: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
		},
		{
			title: "Angular",
			logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/21_Angular_logo_logos-512.png",
		},
	],

	MultimediaProductionandDesign: [
		{
			title: "Adobe Photoshop",
			logo: "https://logodownload.org/wp-content/uploads/2019/10/adobe-photoshop-logo-0.png",
		},
		{
			title: "Adobe Premier Pro",
			logo: "https://logodownload.org/wp-content/uploads/2019/10/adobe-premiere-pro-logo-0-1.png",
		},
		{
			title: "Figma",
			logo: "https://static.vecteezy.com/system/resources/previews/032/050/116/original/figma-3d-icon-free-png.png",
		},
		{
			title: "Fl Studio",
			logo: "https://upload.wikimedia.org/wikipedia/fr/e/ef/FL-Studio-12-Logo.png",
		},
	],
};

export default INFO;
